export const projectList = [
    {
        id: 1,
        prImg: "https://res.cloudinary.com/daaspgfhm/image/upload/v1721120555/Screenshot_2024-07-16_142919_jyqdjw.png",
        heading: "Coin Show",
        title: "Pixonoids",
        about: "A full-stack application facilitating real-time code collaboration. Users can join rooms, each equipped with a code editor. Utilised Socket.IO for room creation and real-time editor interaction, React.js for the frontend, Node.js with Express for the backend socket server.",
        gitLink: "https://github.com/arajshow/coinShow",
        hostedLink: "https://coin-show-red.vercel.app/coin/bitcoin",
    },
    {
        id: 2,
        prImg: "https://res.cloudinary.com/dcjphwzij/image/upload/v1704320256/Screenshot_279_kjwu7e.png",
        heading: "EdTech StudyNotion",
        title: "Pixonoids",
        about: "A full-stack application facilitating real-time code collaboration. Users can join rooms, each equipped with a code editor. Utilised Socket.IO for room creation and real-time editor interaction, React.js for the frontend, Node.js with Express for the backend socket server.",
        gitLink: "https://github/arajshow",
        hostedLink: "https://github/arajshow",
    },
    {
        id: 3,
        prImg: "https://res.cloudinary.com/dcjphwzij/image/upload/v1704325124/Screenshot_283_sfmjon.png",
        heading: "Portfolio",
        title: "Pixonoids",
        about: "A full-stack application facilitating real-time code collaboration. Users can join rooms, each equipped with a code editor. Utilised Socket.IO for room creation and real-time editor interaction, React.js for the frontend, Node.js with Express for the backend socket server.",
        gitLink: "https://github/arajshow",
        hostedLink: "https://github/arajshow",
    },
    {
        id: 4,
        prImg: "https://res.cloudinary.com/dcjphwzij/image/upload/v1704321237/MixCollage-04-Jan-2024-04-03-AM-2255_n6io1x.jpg",
        heading: "Jigsaw Game",
        title: "Pixonoids",
        about: "A full-stack application facilitating real-time code collaboration. Users can join rooms, each equipped with a code editor. Utilised Socket.IO for room creation and real-time editor interaction, React.js for the frontend, Node.js with Express for the backend socket server.",
        gitLink: "https://github.com/arajshow/Jigshow_solver",
        hostedLink: "https://arajshow.github.io/Jigshow_solver/",
    },
 {
        id: 5,
        prImg: "./assert/project5.png",
        heading: "Blog Context",
        title: "Pixonoids",
        about: "A full-stack application facilitating real-time code collaboration. Users can join rooms, each equipped with a code editor. Utilised Socket.IO for room creation and real-time editor interaction, React.js for the frontend, Node.js with Express for the backend socket server.",
        gitLink: "https://github.com/arajshow/BlogContext",
        hostedLink: "https://blog-context-dun.vercel.app/",
    },
    {
        id: 6,
        prImg: "./assert/project6.png",
        heading: "Study More",
        title: "Pixonoids",
        about: "A full-stack application.",
        gitLink: "https://github.com/arajshow/edTech",
        hostedLink: "https://study-wheat.vercel.app/",
    },
    {
        id: 7,
        prImg: "https://res.cloudinary.com/dcjphwzij/image/upload/v1704325124/Screenshot_283_sfmjon.png",
        heading: "Portfolio",
        title: "Pixonoids",
        about: "A full-stack application facilitating real-time code collaboration. Users can join rooms, each equipped with a code editor. Utilised Socket.IO for room creation and real-time editor interaction, React.js for the frontend, Node.js with Express for the backend socket server. A full-stack application facilitating real-time code collaboration. Users can join rooms, each equipped with a code editor. Utilised Socket.IO for room creation and real-time editor interaction, React.js for the frontend, Node.js with Express for the backend socket server. A full-stack application facilitating real-time code collaboration. Users can join rooms, each equipped with a code editor. Utilised Socket.IO for room creation and real-time editor interaction, React.js for the frontend, Node.js with Express for the backend socket server",
        gitLink: "https://github/arajshow",
        hostedLink: "https://github/arajshow",
    },
    {
        id: 8,
        prImg: "https://res.cloudinary.com/dcjphwzij/image/upload/v1704321237/MixCollage-04-Jan-2024-04-03-AM-2255_n6io1x.jpg",
        heading: "Jigsaw Game",
        title: "Pixonoids",
        about: "A full-stack application facilitating real-time code collaboration. Users can join rooms, each equipped with a code editor. Utilised Socket.IO for room creation and real-time editor interaction, React.js for the frontend, Node.js with Express for the backend socket server.",
        gitLink: "https://github/arajshow",
        hostedLink: "https://github/arajshow",
    }
]