import { cn } from "../lib/utils";

export default function Marquee({
  className,
  reverse,
  children,
  repeat = 4,
  ...props
}) {
  return (
    <div
      {...props}
      className={cn(
        `group flex flex-row overflow-hidden p-2 [--gap:1rem] [gap:var(--gap)] transition-all duration-300`,
        className
      )}
    >
      {Array(repeat)
        .fill(0)
        .map((_, i) => (
          <div
            key={i}
            style={{
              animationDirection: reverse ? "reverse" : "normal", // Dynamically setting direction
            }}
            className={`flex shrink-0 justify-around [gap:var(--gap)] animate-marquee  group-hover:animate-paused  transition-all duration-300`}
          >
            {children}
          </div>
        ))}
    </div>
  );
}
