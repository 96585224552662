import React, { useRef, useEffect } from "react";
import { useLocation, NavLink, matchPath } from "react-router-dom";

const generateRandomBorderRadius = () => {
  return `${Math.floor(Math.random() * 100)}% ${Math.floor(
    Math.random() * 100
  )}% ${Math.floor(Math.random() * 100)}% ${Math.floor(
    Math.random() * 100
  )}% / ${Math.floor(Math.random() * 100)}% ${Math.floor(
    Math.random() * 100
  )}% ${Math.floor(Math.random() * 100)}% ${Math.floor(Math.random() * 100)}%`;
};

const Profile = () => {
  const location = useLocation();
  const boxRef = useRef();

  const matchRoute = () => {
    return matchPath({ path: "" }, location.pathname);
  };

  useEffect(() => {
    const imgElement = boxRef.current;

    const createKeyframes = () => {
      return `
        @keyframes borderRadiusAnimation {
          0% { border-radius: '48% 52% 49% 51% / 18% 20% 80% 82%', }
        25% { border-radius: ${generateRandomBorderRadius()}; }
        50% { border-radius: ${generateRandomBorderRadius()}; }
        75% { border-radius: ${generateRandomBorderRadius()}; }
        100% { border-radius: '48% 52% 49% 51% / 18% 20% 80% 82%', }
        }
      `;
    };

    const addKeyframesToDOM = () => {
      const styleSheet = document.createElement("style");
      styleSheet.type = "text/css";
      styleSheet.innerHTML = createKeyframes();
      document.head.appendChild(styleSheet);
      return styleSheet;
    };

    // Add initial keyframes
    let styleSheet = addKeyframesToDOM();

    imgElement.style.animation = "borderRadiusAnimation 10s ease-in-out infinite";

    // Function to handle animation iteration event
    const handleAnimationIteration = () => {
      document.head.removeChild(styleSheet); // Remove old keyframes
      styleSheet = addKeyframesToDOM(); // Add new keyframes
    };

    imgElement.addEventListener("animationiteration", handleAnimationIteration);

    // Cleanup event listener and style sheet on component unmount
    return () => {
      imgElement.removeEventListener(
        "animationiteration",
        handleAnimationIteration
      );
      document.head.removeChild(styleSheet);
    };
  }, []);

  return (
    <NavLink
      to={`/`}
      className={`px-2 ${
        matchRoute() ? "cursor-default" : "cursor-pointer"
      }`}
    >
      <div className="flex flex-row gap-3 px-3 items-center">
        <img
          ref={boxRef}
          className="object-cover w-11 h-11"
          style={{
            borderRadius: "47% 53% 46% 54% / 51% 45% 55% 49%",
            width: "44px",
            height: "44px",
            objectFit: "cover",
          }}
          src="./assert/profileImg.png"
          alt="Profile"
        />
        <div className="flex flex-col items-start gap-0">
          <p className="font-semibold text-sm text-richblack-5">Anand Raj</p>
          <p className="text-sm text-richblack-500"> Web Developer</p>
        </div>
      </div>
    </NavLink>
  );
};

export default Profile;
