import React from "react";
import { skillList } from "../data/skillList";
import SkillBullet from "./Home/SkillBullet";
import Marquee from "../animations/Marquee";

const Skills = () => {
  // Split the skills into two rows, similar to the reviews
  const firstRow = skillList.slice(0, Math.ceil(skillList.length / 3));
  const secondRow = skillList.slice(
    Math.ceil(skillList.length / 3),
    Math.ceil((2 * skillList.length) / 3)
  );
  const thirdRow = skillList.slice(Math.ceil((2 * skillList.length) / 3));

  return (
    <div className="relative flex w-[80%] flex-col items-center justify-center overflow-hidden transition-all duration-300">
      {/* First row marquee */}
      <Marquee className="[--duration:30s]">
        {firstRow.map((data) => (
          <SkillBullet key={data.id} data={data} />
        ))}
      </Marquee>

      {/* Second row marquee (reverse direction) */}
      <Marquee reverse="true" className="[--duration:40s]">
        {secondRow.map((data) => (
          <SkillBullet key={data.id} data={data} />
        ))}
      </Marquee>

      {/* Third row marquee (reverse direction) */}
      <Marquee className="[--duration:30s]">
        {thirdRow.map((data) => (
          <SkillBullet key={data.id} data={data} />
        ))}
      </Marquee>

      {/* Gradient effect on sides */}
      <div className="pointer-events-none absolute inset-y-0 left-0 w-1/5 bg-gradient-to-r from-richblue-900 dark:from-background"></div>
      <div className="pointer-events-none absolute inset-y-0 right-0 w-1/5 bg-gradient-to-l from-richblue-900 dark:from-background"></div>
    </div>
  );
};

export default Skills;
