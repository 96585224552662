export const socialLinks = [
    {
        id: 1,
        name: "Linkedin",
        url: "https://www.linkedin.com/in/arajshow/",
        icon: "VscAccount",
    },
    {
        id: 2,
        name: "Mail",
        url: "https://mail.google.com/mail/u/0/?fs=1&tf=cm&source=mailto&to=id4araj@gmail.com",
        icon: "VscAccount",
    },
    {
        id: 3,
        name: "LeetCode",
        url: "https://leetcode.com/arajshow/",
        icon: "VscAccount",
    },
]