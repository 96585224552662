export const resourceLinks = [
    {
        id: 1,
        name: "Resume",
        url: "https://drive.google.com/drive/folders/1oEy9uyxP1zHLiy0PyiIpVW4iXFYBwVfS",
        icon: "VscAccount",
    },
    {
        id: 2,
        name: "Github",
        url: "https://github.com/arajshow",
        icon: "VscAccount",
    },
] 