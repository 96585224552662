import React, { useEffect, useRef } from 'react';

const MouseTail = () => {
    const coords = { x: 0, y: 0 };
    const circlesRef = useRef([]);

    useEffect(() => {
        const circles = circlesRef.current;

        // Set initial positions and styles for each circle
        circles.forEach((circle, index) => {
            circle.x = 0;
            circle.y = 0;

            // Set the color and size for each circle
            if (index === 0) {
                circle.style.width = '.75rem';  // First circle size
                circle.style.height = '.75rem';
                circle.style.opacity = 1;     // Full opacity for the first circle
            } else if (index === 1) {
                circle.style.width = '2rem';  // Second circle, 2x size of the first
                circle.style.height = '2rem';
                circle.style.opacity = 0.35;   // 50% opacity for the second circle
                circle.style.border = '3px solid blue'; 
            }

            circle.style.backgroundColor = '#ffffff'; // White color for both
            circle.style.mixBlendMode = 'difference';
        });

        const handleMouseMove = (e) => {
            coords.x = e.clientX;
            coords.y = e.clientY;
        };

        window.addEventListener('mousemove', handleMouseMove);

        const animateCircles = () => {
            let x = coords.x;
            let y = coords.y;

            circles.forEach((circle, index) => {
                const nextCircle = circles[index + 1] || circles[0];

                // Adjust interpolation for smoother movement for the second circle
                const interpolationFactor = 0;

                x += (nextCircle.x - x) * interpolationFactor;
                y += (nextCircle.y - y) * interpolationFactor;

                circle.style.left = `${x - circle.offsetWidth / 2}px`;
                circle.style.top = `${y - circle.offsetHeight / 2}px`;

                circle.x = x;
                circle.y = y;
            });

            requestAnimationFrame(animateCircles);
        };

        animateCircles();

        return () => {
            window.removeEventListener('mousemove', handleMouseMove);
        };
    }, []);

    return (
        <div>
            {[...Array(2)].map((_, i) => (
                <div
                    key={i}
                    className="Mouse-circle absolute rounded-full pointer-events-none transition-all ease-out"
                    ref={(el) => circlesRef.current[i] = el}
                    style={{ zIndex: 0 }}
                />
            ))}
        </div>
    );
};

export default MouseTail;
