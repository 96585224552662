export const skillList = [
  {
    id: 1,
    Title: "React",
    iconTitle: "FaReact",
    type: "fa",
  },
  {
    id: 2,
    Title: "JavaScript",
    iconTitle: "SiJavascript",
    type: "si",
  },
  {
    id: 3,
    Title: "TypeScript",
    iconTitle: "SiTypescript",
    type: "si",
  },
  {
    id: 4,
    Title: "Nextjs",
    iconTitle: "TbBrandNextjs",
    type: "tb",
  },
  {
    id: 5,
    Title: "HTML",
    iconTitle: "FaHtml5",
    type: "fa",
  },
  {
    id: 6,
    Title: "CSS",
    iconTitle: "FaCss3Alt",
    type: "fa",
  },
  {
    id: 7,
    Title: "Node.js",
    iconTitle: "FaNodeJs",
    type: "fa",
  },
  {
    id: 8,
    Title: "MongoDB",
    iconTitle: "SiMongodb",
    type: "si",
  },
  {
    id: 9,
    Title: "Cpp",
    iconTitle: "TbBrandCpp",
    type: "tb",
  },
  {
    id: 10,
    Title: "Python",
    iconTitle: "FaPython",
    type: "fa",
  },
  {
    id: 11,
    Title: "Tailwind CSS",
    iconTitle: "SiTailwindcss",
    type: "si",
  },
  {
    id: 12,
    Title: "postgresql",
    iconTitle: "SiPostgresql",
    type: "si",
  },
  {
    id: 13,
    Title: "Git",
    iconTitle: "FaGitAlt",
    type: "fa",
  },
  {
    id: 14,
    Title: "GitHub",
    iconTitle: "FaGithub",
    type: "fa",
  },
  {
    id: 15,
    Title: "Postman",
    iconTitle: "SiPostman",
    type: "si",
  },
  {
    id: 16,
    Title: "SQL",
    iconTitle: "GrMysql",
    type: "gr",
  },
  {
    id: 17,
    Title: "Figma",
    iconTitle: "FaFigma",
    type: "fa",
  },
  {
    id: 18,
    Title: "Appwrite",
    iconTitle: "SiAppwrite",
    type: "si",
  },
];
